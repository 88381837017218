export const appConstants = {
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
  OPEN_MESSAGE_MODAL: 'OPEN_MESSAGE_MODAL',
  CLOSE_MESSAGE_MODAL: 'CLOSE_MESSAGE_MODAL',
  OPEN_MESSAGE_MODAL_V2: 'OPEN_MESSAGE_MODAL_V2',
  CLOSE_MESSAGE_MODAL_V2: 'CLOSE_MESSAGE_MODAL_V2',
  OPEN_DOWNLOAD_MODAL: 'OPEN_DOWNLOAD_MODAL',
  CLOSE_DOWNLOAD_MODAL: 'CLOSE_DOWNLOAD_MODAL',
  OPEN_LANGUAGE_MODAL: 'OPEN_LANGUAGE_MODAL',
  CLOSE_LANGUAGE_MODAL: 'CLOSE_LANGUAGE_MODAL',
  SET_INITIALIZED: 'SET_INITIALIZED',
  SELECT_COMMUNITY_ITEM: 'SELECT_COMMUNITY_ITEM',
  CLEAR_SELECTED_COMMUNITY_ITEM: 'CLEAR_SELECTED_COMMUNITY_ITEM',
  OPEN_CUSTOM_MESSAGE_MODAL: 'OPEN_CUSTOM_MESSAGE_MODAL',
  CLOSE_CUSTOM_MESSAGE_MODAL: 'CLOSE_CUSTOM_MESSAGE_MODAL',
};

export const SubscriptionType = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  PREMIUM: 'PREMIUM',
};

export const Currency = {
  KRW: 'krw',
  USD: 'usd',
  THB: 'thb',
  JPY: 'jpy',
};

//export const backendBaseUrl = 'http://localhost:80';
//export const backendBaseUrl = 'https://api.sorisori.ai';
export const backendBaseUrl = 'https://apibeta.sorisori.ai';
